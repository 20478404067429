<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize toolbar-title">
            <span>Confirm</span>
        </ion-title>
        <ion-button slot="end" fill="none" (click)="closeModal()">
            <ion-icon name="close" class="border-black rounded-full"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<div class="overflow-auto p-[2.75rem] pt-0 flex gap-3">
    <mat-checkbox
        #isInformationVerifiedCheckedMatCheckbox
        (change)="onCheckboxChange($event)"
        [checked]="isChecked"
        [color]="'primary'"
        [required]="true">
    </mat-checkbox>
    <div style="white-space: normal">
        I confirm that I have provided all the Directors / Signatories / UBOs holding more than 25% shares related to my
        entity.
    </div>
</div>

<ion-footer>
    <ion-button class="btn__primary btn__primary-outlined" (click)="closeModal()"> Confirm </ion-button>
</ion-footer>
