<form [formGroup]="kycForm" class="height-min mt-5">
    <div class="mb-5">
        <ion-row class="flex justify-center">
            <ion-col class="flex justify-center" size="12" style="margin-bottom: 40px">
                <h1 class="text-enlarge font-bold text-black">Who is this account being created for?</h1>
            </ion-col>
            <ion-col size="12">
                <ion-row class="flex justify-center">
                    <ion-col
                        size="5"
                        size-sm="12"
                        size-xs="12"
                        size-lg="5"
                        size-xs="12"
                        size-md="5"
                        *ngFor="let item of accountType"
                        class="radio-button-border m-2 flex justify-between"
                        (click)="AccountTypeOnClick(item.companyTypeId)">
                        <mat-radio-group aria-label="Select an option" formControlName="companyTypeId">
                            <mat-radio-button
                                value="{{ item.companyTypeId }}"
                                (change)="AccountTypeOnClick(item.companyTypeId)"
                                >{{ item.value }}</mat-radio-button
                            >
                        </mat-radio-group>
                        <ng-container [ngSwitch]="item.companyTypeId">
                            <ion-icon
                                *ngSwitchCase="'1'"
                                src="assets/icon/contact.svg"
                                size="small"
                                class="text-gray-500"></ion-icon>
                            <ion-icon
                                *ngSwitchCase="'2'"
                                src="assets/icon/office.svg"
                                size="small"
                                class="text-gray-500"></ion-icon>
                            <ion-icon
                                *ngSwitchCase="'3'"
                                src="assets/icon/honour-star.svg"
                                size="small"
                                class="text-gray-500"></ion-icon>
                            <ion-icon
                                *ngSwitchCase="'4'"
                                src="assets/icon/cells.svg"
                                size="small"
                                class="text-gray-500"></ion-icon>
                            <ion-icon
                                *ngSwitchCase="'5'"
                                src="assets/icon/sticky-note-01.svg"
                                size="small"
                                class="text-gray-500"></ion-icon>
                            <ion-icon
                                *ngSwitchDefault
                                name="checkmark-circle-outline"
                                size="small"
                                class="text-gray-500"></ion-icon>
                        </ng-container>
                    </ion-col>
                    <ion-col size="5" class="m-2"></ion-col>
                </ion-row>
            </ion-col>
            <ion-col
                size="11"
                *ngIf="kycForm.get('companyTypeId').value && kycForm.get('companyTypeId').value === '5'"
                class="p-7 pt-2">
                <app-form-field label="label.other_entity_type" required>
                    <mat-form-field>
                        <input matInput required formControlName="otherEntityType" appNoLeadingTrailingSpaces />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>
    </div>
</form>
