import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IonModal} from '@ionic/angular';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GetRowIdParams, GridApi, GridOptions, IDatasource, IGetRowsParams} from 'ag-grid-community';
import {AgGridBtn, AgGridBtnV2} from 'src/app/components/ag-grid-btn';
import {GlobalService} from '../../../../services/global.service';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {PaginatorComponent} from 'src/app/components/paginator';
import {AddStakeholderModal} from './add-stakeholder/add-stakeholder.modal';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {NeedMoreDetailsComponent} from '../need-more-details/need-more-details.modal';
import {Subscription} from 'rxjs';
import {KYCService} from '../../kyc.service';
@Component({
    selector: 'app-kyc-stakeholder',
    templateUrl: './kyc-stakeholders.page.html',
    styleUrls: ['./kyc-stakeholders.page.scss'],
})
export class KycStakeholdersPage implements OnInit {
    @ViewChild(IonModal) optionModal: IonModal;
    @ViewChild('myGrid') myGrid: AgGridAngular;
    @ViewChild('paginator') paginator: PaginatorComponent;
    @Input()
    public disableAddBtn = false;

    companyTypeId = '0';
    public gridOptions = this.getGridOption();
    public gridApi: GridApi;
    public gridColumnApi;
    public gridParams = null;
    private kycCompanySubscription: Subscription;
    totalCount = 0;
    kycBasicInformationId;
    filterData = {
        page: 1,
        limit: 10,
    };
    stakeholders = [];
    kycId;
    /** constructor */
    constructor(
        public global: GlobalService,
        public route: ActivatedRoute,
        public apiResponseService: ApiResponseService,
        public kycDataService: KYCService
    ) {}
    ngOnInit(): void {
        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.updateSource(this.gridParams);
    }

    updateSource(params = this.gridOptions) {
        const datasource: IDatasource = {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            getRows: (params: IGetRowsParams) => {
                //  this.filterData['offset'] = params.startRow;
                this.apiResponseService.getStakeholderInformation().subscribe((response) => {
                    this.totalCount = response.stakeholders.length;
                    if (this.totalCount > 0) {
                        this.kycDataService.atLeastOneStakeholder = true;
                    } else {
                        this.kycDataService.atLeastOneStakeholder = false;
                    }
                    params.successCallback(response.stakeholders, response.stakeholders.length);
                });

                this.gridParams = params;
            },
        };

        this.gridApi.setDatasource(datasource);
    }

    stakeholderInfo() {
        let alertText = '';
        switch (this.companyTypeId) {
            case '2':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the corporation.
                `;
                break;
            case '3':
                alertText = `
                    All trustees and all
                    known beneficiaries
                    and settlors of the
                    trust.
                `;
                break;
            case '4':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the co-ops.
                `;
                break;
            case '5':
                alertText = `
                    All persons who
                    directly or indirectly
                    own or control 25% or
                    more of the shares of
                    the entity.
                `;
                break;
            default:
                alertText = 'Invalid company type ID';
                break;
        }
        return alertText;
    }
    onPaginationChanged($event) {
        this.filterData['page'] = $event;
        this.updateSource(this.gridParams);
    }
    reindexColumns(params) {}
    onFilterChanged($event) {
        this.gridApi.paginationGoToPage(0);
    }
    async addStakeHolder() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: AddStakeholderModal,
            componentProps: {},
            cssClass: 'auto-height ion-disable-focus-trap',
        });

        modal.onDidDismiss().then((detail) => {
            this.updateSource(this.gridParams);
        });

        await modal.present();
    }

    async needMoreDetails() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: NeedMoreDetailsComponent,
            componentProps: {},
            cssClass: 'auto-height ion-disable-focus-trap rounded-lg! p-[3.75rem]',
        });
        modal.onDidDismiss().then((detail) => {});
        await modal.present();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private getGridOption(): GridOptions {
        return {
            rowHeight: 120,
            cacheBlockSize: 100,
            rowModelType: 'infinite',
            floatingFiltersHeight: 50,
            infiniteInitialRowCount: 0,
            enableCellTextSelection: true,
            maxConcurrentDatasourceRequests: 2,
            cacheOverflowSize: 2,
            columnDefs: this.getColumnDefinition(),
            components: {},
            suppressDragLeaveHidesColumns: true,
        };
    }

    onSubmit() {}
    private getColumnDefinition(): ColDef[] {
        return [
            // name
            {
                headerName: 'Name',
                field: 'fullName',
                minWidth: 150,
                flex: 2,
                valueGetter: (params) => {
                    const firstName = params.data?.firstName || '';
                    const lastName = params.data?.lastName || '';
                    return `${firstName} ${lastName}`.trim();
                },
            },

            {
                headerName: 'Address',
                resizable: true,
                field: 'fullAddress',
                sortable: true,
                filter: 'agTextColumnFilter',
                minWidth: 200,
                flex: 3,
                wrapText: true,
                cellStyle: {
                    'padding-left': '18px',
                    'padding-right': '18px',
                    'text-overflow': 'ellipsis',
                    'white-space': 'normal',
                    overflow: 'hidden',
                },
            },

            {
                headerName: 'Designation',
                resizable: false,
                minWidth: 150,
                field: 'designations',
                flex: 1,
                cellRenderer: (params) => {
                    const designations = params.data?.designations || [];
                    const designationList = designations.map((desig) => desig.value).join(', '); // Assuming 'value' contains designation text
                    return `<ul style="list-style-type: disc; margin: 0; font-weight: 600;">${designationList}</ul>`;
                },
                wrapText: true,
                cellStyle: {whiteSpace: 'normal'},
            },

            {headerName: 'Occupation', resizable: false, field: 'occupation', minWidth: 150, flex: 1},

            {
                headerName: 'Date Of Birth',
                resizable: false,
                field: 'dateOfBirth',
                valueGetter: (params) => moment(params.data?.dateOfBirth).format('DD-MMM-YYYY'),
                minWidth: 150,
                flex: 2,
            },
        ];
    }
}
