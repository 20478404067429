export const accountTypes = [
    {
        name: 'Individual',
        value: 1,
    },
    {
        name: 'Corporation',
        value: 2,
    },
    {
        name: 'Trust',
        value: 3,
    },
    {
        name: 'Co-op',
        value: 4,
    },
    {
        name: 'other',
        value: 5,
    },
];
export const thirdPartyManagmentOptions = [
    {
        name: 'Own Properties',
        value: 1,
    },
    {
        name: 'Someone elses properties',
        value: 2,
    },
    {
        name: 'Both',
        value: 3,
    },
];

//create a static variable to help understand which step is currently active

export const nonIndividualSteps: {heading: string; step: number}[] = [
    {heading: 'Corporation Info', step: 2},
    {heading: 'About your business', step: 3},
    {heading: 'Stakeholders Information', step: 4},
    {heading: '', step: 5},
    {heading: 'Application Detail', step: 6},
    {heading: 'Complete Kyc Onboarding', step: 7},
];

export const individualSteps: {heading: string; step: number}[] = [
    {heading: 'Personal Info', step: 2},
    {heading: 'About your business', step: 3},
    {heading: 'Company metadata Information', step: 4},
    {heading: 'Application Detail', step: 5},
    {heading: 'Complete Kyc Onboarding', step: 6},
];

/**
 * An array of employment sectors with their corresponding names and values.
 */
export const employmentSectors: {name: string; value: number}[] = [
    {name: 'Automotive', value: 1},
    {name: 'Banking and Finance', value: 2},
    {name: 'Construction', value: 3},
    {name: 'Education', value: 4},
    {name: 'Energy and Utilities', value: 5},
    {name: 'Government and Public Administration', value: 6},
    {name: 'Healthcare and Pharmaceuticals', value: 7},
    {name: 'Hospitality and Tourism', value: 8},
    {name: 'Information Technology (IT) and Software', value: 9},
    {name: 'Manufacturing', value: 10},
    {name: 'Media and Entertainment', value: 11},
    {name: 'Nonprofit and Social Services', value: 12},
    {name: 'Real Estate', value: 13},
    {name: 'Retail and Wholesale', value: 14},
    {name: 'Telecommunications', value: 15},
    {name: 'Transportation and Logistics', value: 16},
    {name: 'Professional Services (Legal, Accounting, Consulting)', value: 17},
    {name: 'Aerospace and Defense', value: 18},
    {name: 'Environmental Services', value: 19},
];

// create array with name and value for gender male female and not prefer to say
export const genderOptions = [
    {name: 'Male', value: 'male'},
    {name: 'Female', value: 'female'},
    {name: 'Prefer not to say', value: 'PreferedNotToSay'},
];

interface PersonalInfo {
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    occupation: string;
    phoneNumber: string;
    kycEmploymentSectorId: string;
}

interface CompanyInfo {
    companyName: string;
    kycBusinessTypeId: string;
}

interface Address {
    fullAddress: string;
    streetName: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
}

export interface KycUserInfo {
    personalInfo: PersonalInfo;
    companyInfo: CompanyInfo;
    address: Address;
}

export const propertyTypes = [
    {name: 'Apartment', value: '1'},
    {name: 'Condo/Strata', value: '2'},
    {name: 'Co-Op', value: '3'},
    {name: 'Not-For-Profit', value: '4'},
    {name: 'Trailer Park/RV', value: '5'},
    {name: 'Secure Storage', value: '6'},
    {name: 'Retail', value: '7'},
    {name: 'Student Housing', value: '8'},
    {name: 'Other', value: '9'},
];

export function getKycStepNavigation(incompleteBuildingDetails: boolean): {path: string; step: number}[] {
    const steps = [
        {
            path: 'account-type',
            step: 1,
        },
        {
            path: 'business-info',
            step: 2,
        },
        {
            path: 'company-meta-data',
            step: 3,
        },
        {
            path: 'stakeholder-info',
            step: 4,
        },
        {
            path: 'application-details',
            step: 6,
        },
        {
            path: 'identity-verfication',
            step: 7,
        },
    ];

    if (incompleteBuildingDetails) {
        // Insert the new step before 'application-details'
        steps.splice(4, 0, {
            path: 'building-details',
            step: 5,
        });
    }

    return steps;
}

export interface KycStep {
    step: string;
    isStepValid: boolean;
}
export const canadianProvinces: string[] = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'];
