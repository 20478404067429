import {Component, OnInit, ViewChild} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GetRowIdParams, GridApi, GridOptions, IDatasource, IGetRowsParams} from 'ag-grid-community';
import {AgGridBtnV2} from 'src/app/components/ag-grid-btn';
import {PaginatorComponent} from 'src/app/components/paginator';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';
import {UpdateBuildingModalComponent} from './update-building/update-building.modal';
import {KYCService} from '../../kyc.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-incomplete-buildings',
    templateUrl: './incomplete-buildings.component.html',
    styleUrls: ['./incomplete-buildings.page.scss'],
})
export class IncompleteBuildingsPage {
    filterData = {
        page: 1,
        limit: 10,
        orderBy: 'dateDesc',
        searchQuery: '',
        status: 'active',
        bankAccountId: null,
        hasIncompleteAddress: true,
        isYardiProperty: false,
    };
    @ViewChild('myGrid') myGrid: AgGridAngular;
    @ViewChild('paginator') paginator: PaginatorComponent;
    public gridOptions = this.getGridOption();
    public gridApi: GridApi;
    public gridColumnApi;
    public gridParams = null;
    totalCount = 0;

    constructor(
        public global: GlobalService,
        public apiResponseService: ApiResponseService,
        public kycService: KYCService,
        private router: Router
    ) {}
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.updateSource(this.gridParams);
    }

    updateSource(params) {
        const datasource: IDatasource = {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            getRows: (params: IGetRowsParams) => {
                //  this.filterData['offset'] = params.startRow;
                this.global.showLoading();

                const {
                    page,
                    limit,
                    searchQuery,
                    bankAccountId,
                    status,
                    orderBy,
                    hasIncompleteAddress,
                    isYardiProperty,
                } = this.filterData;

                // Convert buildingId to an empty string if it's null
                const sanitizedBankAccountId = bankAccountId === null ? '' : bankAccountId;
                // Call the getUnits function from ApiResponseService
                this.apiResponseService
                    .getBuildingsForKyc(
                        page,
                        limit,
                        searchQuery,
                        sanitizedBankAccountId,
                        status,
                        orderBy,
                        hasIncompleteAddress,
                        isYardiProperty
                    )
                    .subscribe((response) => {
                        if (response.meta['totalCount'] > 0) {
                            this.kycService.disableNextBtn = true;
                            this.gridApi.hideOverlay();
                            this.totalCount = response.meta['totalCount'];
                            params.successCallback(response.data, response.data.length);
                        } else if (response.data) {
                            this.kycService.disableNextBtn = false;
                            this.router.navigate(['/kyc-portal/application-details']);
                            this.totalCount = 0;
                            this.gridApi.showNoRowsOverlay();
                            params.successCallback(response.data, 0);
                        }
                        this.global.hideLoading();
                    });

                this.gridParams = params;
            },
        };

        this.gridApi.setDatasource(datasource);
    }
    onPaginationChanged($event) {
        this.filterData['page'] = $event;
        this.updateSource(this.gridParams);
    }
    private getGridOption(): GridOptions {
        return {
            rowHeight: 67,
            cacheBlockSize: 100,
            rowModelType: 'infinite',
            floatingFiltersHeight: 50,
            infiniteInitialRowCount: 0,
            enableCellTextSelection: true,
            maxConcurrentDatasourceRequests: 2,
            cacheOverflowSize: 2,
            columnDefs: this.getColumnDefinition(),
            components: {buildingBtn: AgGridBtnV2},
            suppressDragLeaveHidesColumns: true,
            getRowId: (params: GetRowIdParams) => params.data.buildingId + '',
        };
    }
    private getColumnDefinition(): ColDef[] {
        return [
            // edit button
            {
                field: 'buildingId',
                headerName: '',
                cellRenderer: 'buildingBtn',
                width: 40,
                cellClass: () => ['btn-col', 'cursor-pointer'],
                // onCellClicked: (field) => this.openBuildingModal(field),
                onCellClicked: (event) => this.rowClicked(event),
            },

            // serial number
            {
                headerName: '#',
                minWidth: 60,
                flex: 1,
                valueGetter: (params) => {
                    const currentPage = this.filterData.page;
                    const rowIndex = params.node.rowIndex + 1;
                    const serialNumber = rowIndex + this.filterData.limit * (currentPage - 1);
                    return serialNumber;
                },
            },

            // building
            {
                headerName: 'Building',
                resizable: false,
                field: 'name',
                sortable: true,
                filter: 'agTextColumnFilter',
                minWidth: 140,
                flex: 1.5,
                filterParams: {
                    buttons: ['reset', 'apply'],
                    debounceMs: 200,
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    filterOptions: ['contains'],
                },
            },

            // account name
            {
                headerName: 'Account Name',
                resizable: false,
                field: 'accountName',
                colId: 'accountName',
                minWidth: 140,
                flex: 1.5,
            },
        ];
    }
    rowClicked($event) {
        this.openBuildingModal($event.data['buildingId']);
    }
    async openBuildingModal(buildingId) {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: UpdateBuildingModalComponent,
            componentProps: {
                buildingId: buildingId,
            },
            cssClass: 'auto-height ion-disable-focus-trap',
        });

        modal.onDidDismiss().then((detail) => {
            this.kycService.checkIncompleteBuildings();
            this.updateSource(this.gridParams);
        });

        await modal.present();
    }
}
