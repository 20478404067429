import {Component, OnInit} from '@angular/core';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-stakeholders-acknowledgement-modal',
    templateUrl: './stakeholders-acknowledgement.modal.html',
})
export class StakeholdersAcknowledgementModal implements OnInit {
    // Add a flag to track the checkbox state
    isChecked = false;

    constructor(public global: GlobalService, private apiResponseService: ApiResponseService) {}

    ngOnInit(): void {
        // Initialization logic goes here
    }

    onCheckboxChange(event: any) {
        this.isChecked = event.checked; // Get the checkbox state

        // If the checkbox is checked, call the API
        if (this.isChecked) {
            this.acknowledgeStakeholders();
        }
    }

    closeModal() {
        this.global.modalCtrl.dismiss({
            isChecked: this.isChecked,
        });
    }

    acknowledgeStakeholders() {
        this.apiResponseService.stakeholdersAcknowledgement().subscribe((response) => {});
    }

    // Add your class methods and properties here
}
