import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from 'src/app/services/global.service';
import {KycStep} from './kyc.constant';
import {ApiResponseService} from 'src/app/services/api-response.service';

@Injectable({
    providedIn: 'root',
})
export class KYCService {
    refId = null;
    details = null;
    sections = [];
    sectionNumber = 0;
    selectedSection = null;
    progressValue = 0;
    private kycData: KycStep[] = [
        {step: 'accountType', isStepValid: false},
        {step: 'businessInfo', isStepValid: false},
        {step: 'companyMetaData', isStepValid: false},
        {step: 'stakeholders', isStepValid: false},
        {step: 'ApplicationReview', isStepValid: false},
        {step: 'FinalPage', isStepValid: false},
    ];
    incompleteBuildingDetails = false;

    curentKycCompanyId = '';
    currentKYCID: string = '';
    private curentKycCompanyIdSubject = new BehaviorSubject<string>(this.curentKycCompanyId);
    private kycDataSubject = new BehaviorSubject<KycStep[]>(this.kycData);
    kycStep$ = this.kycDataSubject.asObservable();
    curentKycCompanyId$ = this.curentKycCompanyIdSubject.asObservable();
    atLeastOneStakeholder = false;
    disableNextBtn = false;
    progressIncrement = 1;
    forData = {};
    constructor(public global: GlobalService, public route: ActivatedRoute, public apiService: ApiResponseService) {}

    nextAction(step, next) {}

    /**
     * Updates the validity of a specific step in the KYC process.
     * @param step - The step to update.
     * @param isValid - The validity status of the step.
     */
    updateStepValidity(step: string, isValid: boolean) {
        const stepIndex = this.kycData.findIndex((s) => s.step === step);
        if (stepIndex !== -1) {
            this.kycData[stepIndex].isStepValid = isValid;
            sessionStorage.setItem('kycSteps', JSON.stringify(this.kycData));
            this.kycDataSubject.next(this.kycData);
        }
    }

    /**
     * Updates the current KYC ID.
     *
     * @param kycID - The new KYC ID to set.
     */
    updateCurrentKYCID(kycID: string) {
        this.currentKYCID = kycID;
    }
    checkIncompleteBuildings() {
        if (this.global.loggedInUser.companyId) {
            this.apiService
                .getBuildingsForKyc(1, 10, '', '', 'active', 'dateDesc', true, false)
                .subscribe((response) => {
                    this.incompleteBuildingDetails = response.meta['totalCount'] > 0;
                });
        }
    }

    /**
     * Updates the validity of a specific step in the KYC process.
     * @param step - The step to update.
     * @param isValid - The validity status of the step.
     */
    updateCurrentKycCompanyId(kycCompanyId: string) {
        if (!kycCompanyId || kycCompanyId.trim() === '') {
            kycCompanyId = this.curentKycCompanyId;
        }
        this.curentKycCompanyIdSubject.next(kycCompanyId);
    }

    getKYCPrerequisite(setSection = null) {
        if (this.sections.length === 0) {
            this.global
                .sendAjaxRequest('kyc/getKYCPrerequisite', {
                    hashId: this.refId,
                })
                .subscribe((response) => {
                    if (response.message === 'success') {
                        this.forData = response.data['forData'];
                        this.sections = response.data['sections'];
                        if (this.sections.length > 0) this.progressIncrement = 1 / this.sections.length;
                        this.details = response.data['details'];
                        if (setSection) {
                            this.setSectionNumber(setSection);
                        }
                    } else {
                        this.global.pushPage('kyc-intro/' + this.refId);
                    }
                });
        }
    }

    setSectionNumber(name) {
        this.sectionNumber = 0;
        for (let index = 0; index < this.sections.length; index++) {
            const element = this.sections[index];
            this.sectionNumber = index;
            if (element['section'] === name) {
                this.selectedSection = element;
                break;
            }
        }
        this.progressValue = (this.sectionNumber + 1) * this.progressIncrement;
        //console.log(this.sectionNumber);
        // this.sections.forEach(element => {
        //     this.sectionNumber++;
        //     console.log(element['section']);
        //     if (element['section'] === name) {
        //         this.selectedSection = element;
        //     }
        // });
    }
    goBackSection() {}
    gotoSection(sectionNumber = null, action) {
        if (sectionNumber === null) {
            if (action === 'next') this.sectionNumber++;
            else this.sectionNumber--;

            if (this.sectionNumber < 0) {
            }
        } else {
            this.sectionNumber = sectionNumber;
        }

        this.selectedSection = this.sections[this.sectionNumber];
        // console.log(this.selectedSection);
        if (this.selectedSection) {
            this.disableNextBtn = true;
            if (this.selectedSection['section'] === 'basicInformation') {
                this.global.pushPage('kyc-basic-info/' + this.refId);
            } else if (this.selectedSection['section'] === 'contactPersonAddress') {
                this.global.pushPage('kyc-contact-address/' + this.refId);
            } else if (this.selectedSection['section'] === 'companyInformation') {
                this.global.pushPage('kyc-company-info/' + this.refId);
            } else if (this.selectedSection['section'] === 'companyAddress') {
                this.global.pushPage('kyc-company-address/' + this.refId);
            } else if (this.selectedSection['section'] === 'nationalIdentity') {
                this.global.pushPage('kyc-identity-doc/' + this.refId + '/nationalIdentity');
            } else if (this.selectedSection['section'] === 'creditCheck') {
                this.global.pushPage('kyc-identity-doc/' + this.refId);
            } else if (this.selectedSection['section'] === 'identityDocument') {
                this.global.pushPage('kyc-identity-doc/' + this.refId + '/identityDocument');
            }

            this.disableNextBtn = false;
            this.progressValue = (this.sectionNumber + 1) * this.progressIncrement;
        } else if (this.sectionNumber < 0) {
            this.global.pushPage('kyc-intro/' + this.refId);
        } else if (this.sectionNumber === this.sections.length) {
            this.global.pushPage('kyc-complete/' + this.refId);
        }
    }
}
