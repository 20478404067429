<div [ngClass]="{'min-width': true}" style="margin-bottom: 100px; overflow-x: auto">
    <ng-container>
        <h3>The following buildings are missing some details. Please provide the necessary updates to proceed</h3>
        <app-table-with-filters>
            <!-- table -->
            <ag-grid-angular
                id="myGrid"
                twf-table
                class="ag-theme-alpine"
                [gridOptions]="gridOptions"
                [overlayNoRowsTemplate]="'errors.no_buildings_found' | translate"
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
            <app-paginator
                [page]="1"
                [limit]="10"
                [totalCount]="totalCount"
                (pageChange)="onPaginationChanged($event)"
                #paginator></app-paginator>
            <!-- (rowClicked)="rowClicked($event)" 
                (paginationChanged)="onPaginationChanged($event)" -->
        </app-table-with-filters>
    </ng-container>
</div>
