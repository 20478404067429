import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';
import {KYCService} from '../../kyc.service';

@Component({
    selector: 'app-application-details',
    templateUrl: './application-details.component.html',
    styleUrls: ['./application-details.component.scss'],
})
export class ApplicationDetailsComponent implements OnInit {
    constructor(
        public translate: TranslateService,
        public apiService: ApiResponseService,
        public global: GlobalService,
        public kycDataService: KYCService
    ) {}
    companyTypeId = '0';
    isInformationVerifiedChecked = false;
    legalCapacityApplied = false;
    triggerTermsAndConditionError = false;
    kycCompanySubscription: Subscription;
    ngOnInit() {
        if (!this.isInformationVerifiedChecked) {
            this.kycDataService.disableNextBtn = true;
        }
        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });
    }

    trackTermsAndConditionsCheckboxClick(value: any) {
        if (value === 'informationVerfied') {
            this.isInformationVerifiedChecked = !this.isInformationVerifiedChecked;
        } else {
            this.legalCapacityApplied = !this.legalCapacityApplied;
        }
        if (this.companyTypeId === '1') {
            if (this.isInformationVerifiedChecked) {
                this.kycDataService.disableNextBtn = false;
            } else {
                this.kycDataService.disableNextBtn = true;
            }
        } else {
            if (this.isInformationVerifiedChecked && this.legalCapacityApplied) {
                this.kycDataService.disableNextBtn = false;
            } else {
                this.kycDataService.disableNextBtn = true;
            }
        }
    }

    onSubmit() {
        if (this.companyTypeId === '1') {
            if (this.isInformationVerifiedChecked) {
                this.triggerTermsAndConditionError = false;
                this.kycDataService.updateStepValidity('ApplicationReview', true);
                this.apiService.submitKycDetails().subscribe(() => {});
            } else {
                this.triggerTermsAndConditionError = true;
            }
        } else {
            if (this.legalCapacityApplied && this.isInformationVerifiedChecked) {
                this.triggerTermsAndConditionError = false;
                this.kycDataService.updateStepValidity('ApplicationReview', true);
                this.apiService.submitKycDetails().subscribe(() => {});
            } else {
                this.triggerTermsAndConditionError = true;
            }
        }
    }
}
