import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-login-preview',
    templateUrl: './login-preview.component.html',
    styleUrls: ['./login-preview.component.scss'],
})
export class LoginPreviewComponent {
    @Input() primaryColor = '#0052f5';
    @Input() secondaryColor = '#7fe26a';
    @Input() logoUrl = '';
    @Input() backgroundImage = '';
    constructor() {}
}
