import {MenuController} from '@ionic/angular';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {NavItem} from './sidebar.model';
import {menuDropInOut} from './animation';
import {ProfileService} from 'src/app/pages/profile';
import {EventService} from 'src/app/services/event.service';
import {GlobalService} from 'src/app/services/global.service';
import {GettingStartedService} from 'src/app/pages/getting-started';
import {KycStatus} from 'src/app/pages/getting-started/getting-started.model';
import {TourService} from 'src/app/services/app-tour.service';
import {UserIdleTimerService} from 'src/app/services/userIdle.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [menuDropInOut],
})
export class SidebarComponent implements OnInit {
    /** main navigation pages */
    appPages: NavItem[] = [];

    @Output('menuCloseOnMatMenu') menuCloseOnMatMenu: EventEmitter<any> = new EventEmitter();
    /** navigation pages part of uncompleted kyc */
    kycProgressPages: NavItem[] = [];

    /** other navigation pages */
    otherPages: NavItem[] = [];

    /** flag used to track state of drop down menu */
    showDropMenu = false;

    /**getting started page details */
    gettingStartedPage: NavItem = {title: 'Getting Started', url: 'getting-started', icon: 'rocket-launch'};

    /** kyc details state */
    kycStatus$ = this.gettingStartedService.kycStatus$;
    showProfile = true;
    /** expose KycStatus enum enum to html  */
    KycStatus = KycStatus;

    readonly DEFAULT_PROFILE = '/assets/icon/person.svg';
    userName = 'John Doe';
    userEmail = 'john.doe@yopmail.com';
    userProfileImg = this.DEFAULT_PROFILE;

    /** constructor */
    constructor(
        public readonly global: GlobalService,
        private readonly eventBus: EventService,
        private readonly menuCtrl: MenuController,
        private readonly profileService: ProfileService,
        private readonly gettingStartedService: GettingStartedService,
        private appGuide: TourService,
        private userIdle: UserIdleTimerService
    ) {
        this._getUserData();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * initialize the menu once the user it logged in and app is initialized
     */
    ngOnInit() {
        this.eventBus.getEvent('AppInitatedSet').subscribe(() => {
            this.showProfile = true;
            this._initMenu();
        });
        this.eventBus.getEvent('SignupLogin').subscribe(() => {
            this.showProfile = false;
            this._initKycMenu();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initialized the menu items
     */
    private _initKycMenu() {
        this.appPages = this._kycPage();
        this.menuCtrl.enable(true);
    }
    private _initMenu() {
        if (!this.global.loggedInUser) {
            return;
        }

        //   this.kycProgressPages = this._getKycProgressPages();
        if (!this.global.loggedInUser.companyId && !this.global.loggedInUser.superUserId) {
            this.showProfile = false;
            this.appPages = this._kycPage();
        } else {
            this.appPages = this._getMainPages();
        }

        this.otherPages = this._getOtherPages();
        this.menuCtrl.enable(true);
    }

    /**
     * get the main page items
     *
     * @returns main page items
     */
    private _getMainPages() {
        const mainPages: NavItem[] = [
            {title: 'Home', url: 'dashboard', icon: 'icon-home'},
            // { title: 'Notifications', url: 'notifications', icon: 'bell' }
            {title: 'Residents', url: 'contacts/residents', icon: 'icon-person-group'},
            {title: 'Buildings', url: 'buildings', icon: 'icon-building'},
            {title: 'Units', url: 'units', icon: 'icon-file-stack'},
            {title: 'Bank Accounts', url: 'bank-accounts', icon: 'icon-bank'},
            {title: 'Transactions', url: 'transactions', icon: 'icon-transactions'},
            {title: 'Billing', url: 'billing', icon: 'icon-bill'},
            {title: 'Reports', url: 'reports', icon: 'icon-bar-graph'},
            {title: 'Notices', url: 'notice-list', icon: 'icon-info'},

            // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
            // {title: 'Spam', url: '/folder/Spam', icon: 'warning'},
            // { title: 'Mangers', url: 'contacts/managers', icon: 'user-tie' }
        ];

        if (this.global.allowResidentLogin) {
            mainPages.push({title: 'Maintaince Request', url: 'work-order-list', icon: 'hammer'});
        }
        if (this.global.loggedInUser.userRole === 'admin') {
            mainPages.push({title: 'Managers', url: 'contacts/managers', icon: 'icon-manager'});
        }
        if (this.global.loggedInUser.userRole === 'admin') {
            mainPages.push({title: 'Integrations', url: 'integrations', icon: 'icon-puzzle-piece'});
        }

        /* const integrationPage = {title: 'Integrations', url: 'integrations', icon: 'icon-puzzle-piece'};
        mainPages.push(...this.kycProgressPages, integrationPage); */

        if (this.global.loggedInUser['yardiCompany']) {
            mainPages.push({title: 'Yardi', url: 'yardi-buildings', icon: '', isSubItem: true});
        }
        if (this.global.pendingKyc) {
            mainPages.splice(1, 0, {title: 'KYC', url: 'kyc-portal', icon: 'icon-info'}); // Insert after Home
        }

        return mainPages;
    }

    private _kycPage() {
        const mainPages: NavItem[] = [{title: 'Get Started', url: '', icon: 'icon-info'}];
        return mainPages;
    }
    /**
     * get the other page items
     *
     * @returns other page items
     */
    private _getOtherPages() {
        const pages = [
            {title: 'Help', url: null, icon: 'information-circle'},
            {title: 'Profile', url: 'profile', icon: 'user-circle'},
            {title: 'Logout', url: null, icon: 'arrow-right-on-rectangle'},
        ];

        // Conditionally add the "Add Branding" page
        if (this.global.hasSubdomain) {
            pages.splice(pages.length - 1, 0, {title: 'Add Branding', url: 'add-branding', icon: 'paint-brush'});
        }

        return pages;
    }

    /**
     * get the kyc progress page
     *
     * @returns other page items
     */
    private _getKycProgressPages() {
        const kycProgressPages: NavItem[] = [];
        // temporary hidden
        //  [{title: 'Account Information', url: 'account-information', icon: 'cog'}];
        if (this.global.loggedInUser.userRole === 'admin') {
            kycProgressPages.unshift({title: 'Managers', url: 'contacts/managers', icon: 'icon-manager'});
        }
        return kycProgressPages;
    }

    routeToPage(page: NavItem) {
        // if the URL exist in page then navigate to the URL

        if (page.url) {
            this.global.navCtrl.navigateRoot(page['url']);
            this.menuCloseOnMatMenu.emit();
            return;
        }

        // perform actions based on the selected page title
        switch (page.title) {
            case 'Logout': {
                this.appGuide.isTourDestroyed().subscribe((isDestroyed) => {
                    if (!this.appGuide.isTourCompleted()) {
                        this.appGuide.hideSteps();
                    }
                });

                this.global.logoutUser();
                break;
            }
            case 'Help': {
                this.global.launchExternal('https://help.tenantpay.com/support/home');
                break;
            }
        }
    }

    /**
     * get the user's updated data every time there is a change in user profile
     */
    private _getUserData() {
        this.profileService.profileDetails$.subscribe((profileDetails) => {
            if (!profileDetails) {
                return;
            }
            this.userName = `${profileDetails.firstName} ${profileDetails.lastName}`;
            this.userEmail = profileDetails.email;
            this.userProfileImg = profileDetails.profilePictureUrl || this.DEFAULT_PROFILE;
        });
    }
}
