<form [formGroup]="companyMetadataForm" class="pt-0 pb-0 max-width" [ngClass]="{'min-h-[530px]': !disableForm}">
    <ion-row class="flex flex-col items-center">
        <ion-col size="12" formGroupName="companyMetaData">
            <app-form-field
                [label]="'label.monthly_transaction_amount' | translate"
                [showButtonInfo]="true"
                [matTooltipText]="monthlyTransactionInfo()"
                required>
                <mat-form-field class="flex justify-start">
                    <span class="currencyinput mr-2">CA$</span>
                    <input
                        name="monthlyTransactionAmount"
                        matInput
                        required
                        mask="separator.2"
                        thousandSeparator=","
                        formControlName="monthlyTransactionAmount" />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="12" formGroupName="companyMetaData">
            <app-form-field label="label.accounting_software_type">
                <mat-form-field>
                    <mat-select
                        formControlName="accountingSoftwareTypeId"
                        panelClass="mat-select-option-panal"
                        disableOptionCentering>
                        <mat-option
                            *ngFor="let accountingSoftware of accountingSoftwares"
                            value="{{ accountingSoftware.accountingSoftwareTypeId }}"
                            >{{ accountingSoftware.value }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </app-form-field>
        </ion-col>

        <ion-col
            size="12"
            formGroupName="companyMetaData"
            *ngIf="companyMetadataForm.get('companyMetaData').get('accountingSoftwareTypeId').value === '11'">
            <app-form-field label="label.accounting_software_name" required>
                <mat-form-field>
                    <input matInput required formControlName="accountingSoftware" />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="12" formGroupName="companyMetaData">
            <app-form-field label="label.what_type_of_properties" class="w-100" required>
                <ion-row>
                    <ion-col
                        *ngFor="let propertyTypes of propertyTypesList"
                        size="4"
                        size-sm="6"
                        size-lg="4"
                        size-xs="12"
                        size-md="4">
                        <mat-checkbox
                            id="{{ 'apartmentChkBx_' + propertyTypes.name }}"
                            [disabled]="disableForm"
                            [checked]="apartmentSelected.includes(propertyTypes.name)"
                            [color]="'primary'"
                            (change)="checkboxOnclick(propertyTypes.name)"
                            [value]="propertyTypes.name"
                            >{{ propertyTypes.name }}</mat-checkbox
                        >
                    </ion-col>
                </ion-row>
            </app-form-field>
        </ion-col>
        <ion-col size="12" formGroupName="companyRealEstate" *ngIf="checkIfOtherApartment()">
            <app-form-field label="label.other_property_types" required>
                <mat-form-field>
                    <input matInput required formControlName="otherRealEstateType" appNoLeadingTrailingSpaces />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="12" formGroupName="companyMetaData" *ngIf="companyTypeId === '4'">
            <app-form-field label="label.co_op_organization_association" required>
                <mat-form-field>
                    <mat-select
                        formControlName="coopAssociationTypeId"
                        panelClass="mat-select-option-panal"
                        disableOptionCentering>
                        <mat-option *ngFor="let coopType of coopTypes" value="{{ coopType.coopAssociationTypeId }}">{{
                            coopType.value
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col
            size="12"
            formGroupName="companyMetaData"
            *ngIf="
                companyTypeId === '4' &&
                companyMetadataForm.get('companyMetaData').get('coopAssociationTypeId').value === '26'
            ">
            <app-form-field label="label.other" required>
                <mat-form-field>
                    <input matInput required formControlName="otherCoopAssociationType" appNoLeadingTrailingSpaces />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
    </ion-row>
</form>
