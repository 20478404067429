import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-update-building-modal',
    templateUrl: './update-building.modal.html',
    styleUrls: ['./update-building.modal.scss'],
})
export class UpdateBuildingModalComponent {
    public building: any = {
        isInterCompany: '0',
    };
    public originalBuilding: any = {
        isInterCompany: '0',
    };
    public isYardiBuilding = false;
    public accountAddress: any = {};
    public hideOtherFields = true;
    public hideNextAction = true;

    public bankAccountId: any;

    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    public addYardi;
    public bankAccount: any[] = [];

    public googleAddress = true;
    public selectedYardiEntry: string;
    public photoUploadReq: any;
    bankAccountName;

    public editModal = false;
    fullAddress;
    public buildingId: number | null = null;

    @ViewChild('saveBuildingForm', {static: true}) saveBuildingForm: NgForm;
    constructor(public global: GlobalService, public modalCtrl: ModalController, private navParams: NavParams, private changeDetectorRef: ChangeDetectorRef, private apiResponseService: ApiResponseService) {}
    hideBankAccount = true;
    ionViewWillEnter(): void {
        // eslint-disable-next-line radix
        this.buildingId = parseInt(this.navParams.get('buildingId'));
        this.editModal = true;
        this.hideOtherFields = false;
        this.hideBankAccount = false;
        this.getBuildingDetails();
        setTimeout(() => {
            if (this.saveBuildingForm) {
                this.saveBuildingForm.form.markAllAsTouched();
            }
        }, 0);
    }

    getBuildingDetails(): void {
        this.global.showLoading();
        this.global.sendAjaxRequest('building/getBuildingDetails', {buildingId: this.buildingId}).subscribe((response) => {
            if (response.message === 'success') {
                this.building = response.data;
                this.fullAddress = response.data.fullAddress;
                this.bankAccountId = this.building['bankAccountId'];
                this.getBankAccountDetails();
                this.originalBuilding = JSON.parse(JSON.stringify(this.building));
            }
            this.global.hideLoading();
        });
    }
    getBankAccountDetails(): void {
        this.global.sendAjaxRequest('bank/getBankDetails', {bankAccountId: this.bankAccountId}).subscribe((response) => {
            const data = response['data'];
            this.bankAccountName = data?.bankAccount?.accountName;
        });
    }

    isEdited(): boolean {
        return JSON.stringify(this.originalBuilding) !== JSON.stringify(this.building);
    }

    saveBuilding(saveBuildingForm): void {
        saveBuildingForm.form.markAllAsTouched();
        if (!this.isEdited()) {
            this.global.closeModal(true);
            return;
        } else {
            const boxes = document.querySelectorAll('[combo-box-input]');
            Object.keys(boxes).forEach((key: string) => {
                boxes[key].focus();
                boxes[key].blur();
            });
            const locations = document.querySelectorAll('[location-search-input]');
            Object.keys(locations).forEach((key: string) => {
                locations[key].focus();
                locations[key].blur();
            });
            if (this.global.isFormValid(saveBuildingForm)) {
                this.sendSaveBuildingRequest();
            }
        }
    }

    trimmedValuesEmpty() {
        if (this.building['name'].trim() === '' || this.building['city'].trim() === '' || this.building['country'].trim() === '' || this.building['province'].trim() === '') {
            return true;
        } else {
            return false;
        }
    }

    sendSaveBuildingRequest(): void {
        this.building['fullAddress'] = [this.building['buildingNumber'], this.building['streetName'], this.building['city'], this.building['province'], this.building['postalCode']].filter(Boolean).join(', ');
        if (typeof this.building['bankAccountId'] !== 'undefined' && this.building['bankAccountId'] !== null && this.building['bankAccountId'] !== 0) {
            this.global.showLoading();
            this.global.sendAjaxRequest('building/saveBuilding', {buildingData: this.building}).subscribe((response) => {
                if (response.message === 'added') {
                    this.global.basicAlert('', 'label.building_added', true);
                    if (!this.global.loggedInUser['yardiCompany'] && this.building['yardiIntegrationId']) {
                        this.global.loginEssential(false);
                    }
                    this.global.closeModal(true);
                } else if (response.message === 'already_exist') {
                    this.global.basicAlert('', 'label.building_already_exist', true);
                } else if (response.message === 'updated') {
                    this.global.basicAlert('', 'label.building_updated', true);
                    this.global.closeModal(true);
                } else if (response.message === 'cannot_change_yardi_integration') {
                    this.global.basicAlert('', 'label.cannot_change_yardi_integration', true);
                } else if (response.message === 'property_id_already_exist') {
                    this.global.basicAlert('', 'label.property_id_already_exist', true);
                }
                this.global.hideLoading();
            });
        } else {
            this.global.basicAlert('', 'errors.please_fill_all_required_fields', true);
        }
    }

    locationResults(place): void {
        //const address = this.global.processAdress(place['address_components']);
        this.building['country'] = place['country'];
        this.building['province'] = place['province'];
        this.building['city'] = place['city'];
        this.building['buildingNumber'] = place['buildingNumber'];
        this.building['streetName'] = place['streetName'];
        this.building['postalCode'] = place['postalCode'];
        this.building['fullAddress'] = place['fullAddress'];
        this.hideOtherFields = false;

        this.changeDetectorRef.detectChanges();
    }
}
